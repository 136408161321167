(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#travel-tour', $container);

        var $requestButton = $('.request', $root);
        if ($('button', $requestButton).attr('disabled')) {
            var $stopSaleSnackbar = $('.stopSale__snackbar', $root);
            $requestButton.hover(
                function () {
                    $stopSaleSnackbar.removeClass('is-hidden');
                },
                function () {
                    $stopSaleSnackbar.addClass('is-hidden');
                },
            );
        }

        if ($root.length === 0) return;

        // On Back, radio appears check but is not, and select is empty
        // Reset radio inputs to avoid user frustration
        $(window).bind('pageshow', function (event) {
            $('input[name=startDateTable]').attr('checked', false);
        });

        /**
         * On mobile, refresh the page if cache is used to avoid form cache persist.
         *
         * @see https://stackoverflow.com/a/13123626
         * @see https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Releases/1.5/Using_Firefox_1.5_caching
         */
        $(window).bind('pageshow', function (event) {
            if (event.originalEvent.persisted) {
                window.location.reload();
            }
        });

        (function () {
            var $asidePanel = $('#aside-panel', $root);
            $('#bookbutton', $asidePanel).on('click', function (event) {
                var error = false;
                $('.next-dates-error', $root).hide();
                $('.children-age-error', $root).hide();
                $('.children-number-error', $root).hide();

                var isDesktop = window.innerWidth >= 1300;

                if ($('#next-dates', $root).val() === '') {
                    error = true;

                    if (isDesktop) {
                        $('.next-dates-error', $root).css('display', 'flex');
                    }

                    if ($('#travel-tour-dates').length > 0) {
                        $('html,body').animate(
                            {
                                scrollTop: $('#travel-tour-dates').offset().top,
                            },
                            1000,
                        );
                    }
                }

                if ($('#nbChildren', $root).val() === '') {
                    error = true;
                    if (isDesktop) {
                        $('.children-number-error', $root).css(
                            'display',
                            'flex',
                        );
                    }
                }

                $('[name="childrenAge[]"]', $root).each(function () {
                    if ($(this).val() === '') {
                        if (isDesktop) {
                            $('.children-age-error', $root).css(
                                'display',
                                'flex',
                            );
                        }

                        error = true;
                    }
                });

                if (error) {
                    event.preventDefault();
                    return false;
                }
            });

            $('#form-book-dates', $asidePanel).on('submit', () => {
                const bookButton = $('#bookbutton');
                if (bookButton[0]) bookButton[0].disabled = true;
            });

            // Synchronize dates table and dates select.
            var hasShowMoreButton =
                '{{ @datesCount.total>@datesCount.visible }}';
            var totalDatesVisible = '{{ @datesCount.visible }}';

            $('input[name=startDateTable]').on('click', function (event) {
                event.stopPropagation();
            });

            $(document).on('change', 'input[name=startDateTable]', function () {
                var selectedDate = $(this).val();
                $('select[name=startDate]').val(selectedDate);
                updateAside(selectedDate);
            });

            $asidePanel.on('change', 'select[name=startDate]', function () {
                var $startDateSelect = $('select[name=startDate]');
                var selectedDate = $startDateSelect.val();
                var selectedDateIndex = $startDateSelect.prop('selectedIndex');

                updateAside(selectedDate);
                $('input[name=startDateTable]:checked').prop('checked', false);

                if (selectedDate) {
                    $(
                        'input[name=startDateTable][value = ' +
                            selectedDate +
                            ']',
                    ).prop('checked', true);
                }

                // If the selected date is not shown, display the rest of dates inside the table.
                if (
                    hasShowMoreButton &&
                    selectedDateIndex > totalDatesVisible
                ) {
                    $('#action-showmore-dates').click();
                }
            });
        })();

        // Navigation links
        (function () {
            var $links = $('#travel-tour-links', $root),
                $linksInner = $('> .inner', $links),
                $nav = $('.travel-tour-links-nav', $links),
                $navLinks = $('> .link', $nav);

            var $headerLink = $('#header-link');

            // Enable links dragging on small screens
            var $linksDragContainment;
            function refreshLinksDragContainment() {
                var linksWidth = $linksInner.width(),
                    overflow = Math.max(
                        0,
                        linksWidth - $linksInner.parent().width(),
                    );
                $linksDragContainment.css({
                    left: -1 * overflow + 'px',
                    width: linksWidth + overflow + 'px',
                });
                // make sure $linksInner has not moved outside containment while resizing windows
                var linksInnerPosition = $linksInner.position();
                if (
                    linksInnerPosition.left > 0 ||
                    linksInnerPosition.left < -1 * overflow
                ) {
                    $linksInner.css({
                        left:
                            linksInnerPosition.left > 0
                                ? '0'
                                : -1 * overflow + 'px',
                    });
                }
            }
            function toggleDraggableLinks() {
                if (
                    window.matchMedia(
                        '(min-width: ' + app.config.breakpoints.large + 'px)',
                    ).matches
                ) {
                    // large screens: disable drag mode
                    if ($linksDragContainment) {
                        $linksInner.draggabilly('destroy');
                        $linksDragContainment.remove();
                        $linksDragContainment = null;
                    }
                } else {
                    // small screens: enable drag mode or refresh containment
                    if ($linksDragContainment) {
                        refreshLinksDragContainment();
                    } else {
                        if ($linksInner.length !== 0) {
                            $linksDragContainment = $(
                                '<div class="travel-tour-links-drag-containment"></div>',
                            )
                                .css({
                                    position: 'absolute',
                                    top:
                                        $linksInner.position().top +
                                        parseInt(
                                            $linksInner.css('marginTop'),
                                            10,
                                        ),
                                    height: $linksInner.height() + 'px',
                                })
                                .insertBefore($linksInner);
                            refreshLinksDragContainment();
                            $linksInner.draggabilly({
                                axis: 'x',
                                containment: $linksDragContainment[0],
                            });
                        }
                    }
                }
            }

            $(window).on('resize', $.debounce(100, toggleDraggableLinks));
            toggleDraggableLinks();

            // Enable scroll animation on navigation links
            function getScrollThreshold($target) {
                return Math.max(
                    0,
                    Math.floor($target.offset().top - $nav.height() - 10),
                );
            }

            function smoothScroll(event) {
                event.preventDefault();
                var $link = $(this),
                    $target = $($link.attr('href'), $root);
                $('html, body').animate(
                    {
                        scrollTop: getScrollThreshold($target),
                    },
                    750,
                );
            }

            $navLinks.on('click', smoothScroll);
            if ($headerLink) {
                $headerLink.on('click', smoothScroll);
            }

            // Handle .is-active class on navigation links
            function activateCurrentNavigationLink() {
                var $latestActiveLink;
                $navLinks
                    .each(function (i, e) {
                        var $link = $(e),
                            $target = $($link.attr('href'), $root);
                        if (
                            $target.length > 0 &&
                            window.scrollY >= getScrollThreshold($target)
                        ) {
                            $latestActiveLink = $link;
                            //  mobile scroll current active link into viewport
                            // if ($link[0]) $link[0].scrollIntoView();
                        }
                    })
                    .removeClass('after:w-full')
                    .removeClass('text-content-brand');
                $latestActiveLink &&
                    $latestActiveLink.addClass('after:w-full') &&
                    $latestActiveLink.addClass('text-content-brand');
            }
            $(window).on(
                'scroll',
                $.debounce(100, activateCurrentNavigationLink),
            );
            activateCurrentNavigationLink();
        })();

        // Program
        (function () {
            var $program = $('#travel-tour-program', $root);

            // Activate showmore-program button
            $('#action-showmore-program', $program).on('click', () => {
                $('[data-travel-tour-program-stage]')
                    .removeClass('hidden')
                    .addClass('medium:flex');
                $('[data-travel-tour-program-showmore]').remove();
            });
        })();

        // Dates
        (function () {
            var $dates = $('#travel-tour-dates', $root);

            // Activate showmore-dates button
            $('#action-showmore-dates', $dates).on('click', function () {
                $('[data-travel-tour-dates-datarow]').removeClass('hidden');
                $('[data-travel-tour-dates-showmore]').remove();
            });

            $('.travel-tour-dates-table').on(
                'click',
                '[data-travel-tour-dates-datarow]',
                function () {
                    $(this).find('input[type=radio]').click();
                },
            );

            // Responsive table
            $('.table', $dates).responsiveTable();
        })();

        function updateAside(selectedDate) {
            var $description = $('.travelers__description');
            var $status = $('.travelers__status');
            var $tooltip = $('.travelers__tooltip');

            var $actualprice = $('[data-aside-panel-actualprice-target]');
            var $minprice = $('[data-aside-panel-minprice-target]');
            var $rebatePercentage = $(
                '[data-aside-panel-rebatepercentage-target]',
            );
            var $fromLabel = $('[data-aside-panel-fromlabel-target]');

            if (!selectedDate) {
                $description.addClass('travelers__description--none');
                $minprice.removeClass('is-hidden minprice--small');
                $status.text('');
                $tooltip.text('');
                return;
            }

            // Update dates
            $minprice.addClass('minprice--small');

            // Update status
            var $activeStatus = $(
                '.travel-tour-dates__status[data-date=' + selectedDate + ']',
            );

            var tooltip = $activeStatus.data('tooltip');
            var status = $activeStatus.data('status');

            $description.removeClass('travelers__description--none');
            $status.text(status);
            $tooltip.text(tooltip);

            // fetch selected date element
            var $activePrice = $(
                '.travel-tour-dates__price[data-date=' + selectedDate + ']',
            );
            // retrieve all prices
            var price = $activePrice.data('price');
            var rebatedPrice = $activePrice.data('rebatedprice');
            var rebatePercentage = $activePrice.data('rebatepercentage');
            $fromLabel.addClass('hidden');

            // display discounts
            if (rebatedPrice) {
                $minprice.removeClass('hidden');
                $rebatePercentage.removeClass('hidden');
                $minprice.html(price);
                $rebatePercentage.html(rebatePercentage + '%');
                $actualprice.html(rebatedPrice);
            } else {
                // default case, normal price
                $minprice.addClass('hidden');
                $rebatePercentage.addClass('hidden');
                $actualprice.html(price);
            }

            $('.next-dates-error').hide();

            // Update max travellers
            var $nbAdults = $('input[name=nbAdults]');
            var $nbChildren = $('input[name=nbChildren]');
            var remainingStock = document.querySelector(
                'select[name=startDate] option[value="' + selectedDate + '"]',
            ).dataset.remainingStock;
            if (remainingStock !== null) {
                $nbAdults.attr('max', remainingStock);
                $nbAdults.attr('maxStock', remainingStock);
                $nbAdults.trigger('change-max', remainingStock);
            }
            // change date: reset input state
            $nbAdults.val($nbAdults.attr('min')).trigger('input');
            $nbChildren.val('0').trigger('input');
            if ($nbAdults[0]) {
                $nbAdults[0].dispatchEvent(
                    new Event('input', { bubbles: true }),
                );
                $nbAdults[0].dispatchEvent(new Event('reset'));
            }
            if ($nbChildren[0]) {
                $nbChildren[0].dispatchEvent(
                    new Event('input', { bubbles: true }),
                );
                $nbChildren[0].dispatchEvent(new Event('reset'));
            }
        }

        // Reviews
        (function () {
            var $reviews = $('#travel-tour-reviews', $root);

            // Display modal
            var $reviewsModalButton = $('.action-showmore-reviews', $reviews);
            if ($reviewsModalButton) {
                $reviewsModalButton.on('click', function () {
                    $('.reviews__modal', $reviews).removeClass('is-hidden');
                    document.querySelector('body').style.overflow = 'hidden';
                });
            }

            // Close modal
            $('.reviews__modal', $reviews).on('click', function (e) {
                if (
                    e.target.classList.contains('reviews__modal') ||
                    e.target
                        .closest('span')
                        ?.classList.contains('reviews__modal__close')
                ) {
                    $('.reviews__modal', $reviews).addClass('is-hidden');
                    document.querySelector('body').style.overflow = 'auto';
                }
            });
        })();
    });
})(jQuery, window.app);
