(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#booking-block', $container);

        if ($root.length === 0) return;

        var min = 1;
        var max = 10;
        var $quantity_div = $('#quantity', $root);

        $('#quantity-selector-input').on('change-max', function (event) {
            var quantity = parseInt($('#quantity-selector-input').val());
            var newMax = parseInt($(this).attr('max'));

            if (quantity < newMax) {
                removeAttrDisabled(
                    $('#quantity-selector-button-plus', $quantity_div),
                );
            }

            if (quantity > newMax) {
                $('#quantity-selector-input').val(newMax);
            }

            max = newMax;
        });

        $('#quantity-selector-button-minus', $quantity_div).on(
            'click',
            function () {
                var quantity = parseInt($('#quantity-selector-input').val());
                min = this.dataset.minpax;
                var newQuantity = quantity - 1;

                if (newQuantity < min) {
                    newQuantity = min;
                }

                if (newQuantity < max) {
                    removeAttrDisabled(
                        $('#quantity-selector-button-plus', $quantity_div),
                    );
                }

                if (newQuantity === min) {
                    addAttrDisabled(
                        $('#quantity-selector-button-minus', $quantity_div),
                    );
                }

                $('#quantity-selector-input', $quantity_div).val(newQuantity);
            },
        );

        $('#quantity-selector-button-plus', $quantity_div).on(
            'click',
            function () {
                var quantity = parseInt($('#quantity-selector-input').val());
                var newQuantity = quantity + 1;

                if (newQuantity > max) {
                    newQuantity = max;
                }

                if (newQuantity < max) {
                    removeAttrDisabled(
                        $('#quantity-selector-button-minus', $quantity_div),
                    );
                }

                if (newQuantity === max) {
                    addAttrDisabled(
                        $('#quantity-selector-button-plus', $quantity_div),
                    );
                }

                $('#quantity-selector-input', $quantity_div).val(newQuantity);
            },
        );

        $('#quantity-selector-input', $quantity_div).on('change', function () {
            if (isNaN(parseInt($('#quantity-selector-input').val()))) {
                $('#quantity-selector-input', $quantity_div).val(min);
                addAttrDisabled(
                    $('#quantity-selector-button-minus', $quantity_div),
                );
                removeAttrDisabled(
                    $('#quantity-selector-button-plus', $quantity_div),
                );
            }
            if (parseInt($('#quantity-selector-input').val()) < min) {
                $('#quantity-selector-input', $quantity_div).val(min);
                addAttrDisabled(
                    $('#quantity-selector-button-minus', $quantity_div),
                );
                removeAttrDisabled(
                    $('#quantity-selector-button-plus', $quantity_div),
                );
            }
            if (parseInt($('#quantity-selector-input').val()) > max) {
                $('#quantity-selector-input', $quantity_div).val(max);
                addAttrDisabled(
                    $('#quantity-selector-button-plus', $quantity_div),
                );
                removeAttrDisabled(
                    $('#quantity-selector-button-minus', $quantity_div),
                );
            }
        });

        function addAttrDisabled(elem) {
            elem.attr('disabled', 'disabled');
        }

        function removeAttrDisabled(elem) {
            elem.removeAttr('disabled');
        }
    });
})(jQuery, window.app);
