(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $forms = $('form', $container);

        if ($forms.length === 0) return;

        // Datepicker
        $('.datepicker', $forms)
            .pikaday(app.config.pikaday)
            .attr('autocomplete', 'off');
    });
})(jQuery, window.app);
