(function ($) {
    function ajaxify($form, o) {
        o = $.extend(
            {
                dataType: 'json',
                sendingClass: 'ajaxform-sending',
                findTarget: null,
            },
            o,
        );
        var sending = false,
            app = window.app;
        function getPathSelector(path) {
            var selector = '',
                els = path.split(':'),
                last = els.length - 1;
            $.each(els, function (i, e) {
                selector += selector ? '[' + e + ']' : e;
                if (i === last) {
                    // last element
                    if (e.match(/^\d+$/)) {
                        // numeric: foo[2],foo[]:eq(2)
                        selector =
                            '[name="' +
                            selector +
                            '"],[name="' +
                            selector.replace(/\[\d+\]$/, '[]') +
                            '"]:eq(' +
                            e +
                            ')';
                    } // otherwise foo,foo[]
                    else
                        selector =
                            '[name="' +
                            selector +
                            '"],[name="' +
                            selector +
                            '[]"]';
                }
            });
            return selector;
        }
        function findTarget(selector, $form) {
            return $(selector, $form).filter(':visible');
        }
        $form.on('submit.ajaxform', function (event) {
            var $fields = $('[name]', $form);
            if ($fields.filter('[type="file"]').length > 0) return; //forms containing file inputs can't be submitted by ajax (jquery.iframe-transport.js would be required)
            event.preventDefault();
            if (sending) return false;
            sending = true;
            $form.addClass(o.sendingClass);
            $.quicktip.detach($('.has-quicktip', $form));
            $('.warning', $form).removeClass('warning');
            app.displayMsg();
            $.ajax({
                method: $form.attr('method'),
                url: $form.attr('action'),
                dataType: o.dataType,
                data: $form.serialize(),
            })
                .done(function (response) {
                    var out = { stop: false };
                    $form.trigger($.ajaxForm.DONE, [response, out]);
                    if (out.stop) return;
                    if (response.reroute) {
                        app.reroute(response.reroute);
                    } else {
                        response.msg
                            ? app.displayMsg(response.msg)
                            : app.displayMsg(
                                  'The form has been submitted',
                                  'ok',
                              );
                    }
                })
                .fail(function (XHR) {
                    var error = XHR.responseJSON,
                        out = { stop: false };
                    $form.trigger($.ajaxForm.FAIL, [error, out]);
                    if (out.stop) return;
                    if (!error) {
                        app.displayMsg('Unknown error');
                        return;
                    }
                    if (error.msg && $.isArray(error.msg))
                        $.each(error.msg, function (i, m) {
                            var $target = $();
                            if (m.path) {
                                var selector = getPathSelector(m.path);
                                $target = o.findTarget
                                    ? o.findTarget.apply(null, [
                                          selector,
                                          $form,
                                          findTarget,
                                      ])
                                    : findTarget(selector, $form);
                            }
                            if ($target.length === 0) app.displayMsg(m);
                            else if (!$target.hasClass('warning'))
                                // only the first warning is shown
                                $target
                                    .quicktip({
                                        text: m.text,
                                        class: 'warning',
                                    })
                                    .addClass('warning');
                        });
                    app.displayMsg(
                        (error.text.match(/^HTTP/)
                            ? error.status
                            : error.text) || 'Unknown error',
                    );
                    $('.warning', $form).first().focus(); //focus sur le premier champ en erreur
                })
                .always(function () {
                    $form.trigger($.ajaxForm.ALWAYS);
                    sending = false;
                    $form.removeClass(o.sendingClass);
                });
        });
    }

    // Event constants
    $.ajaxForm = {
        DONE: 'ajaxForm:done',
        FAIL: 'ajaxForm:fail',
        ALWAYS: 'ajaxForm:always',
    };

    $.fn.ajaxForm = function (o) {
        return this.each(function (i, e) {
            ajaxify($(e), o);
        });
    };
})(jQuery);
