(function ($) {
    // cf. https://stackoverflow.com/questions/4814398/how-can-i-check-if-a-scrollbar-is-visible/4814526#4814526
    var scrollbarWidth;
    function getWidth() {
        //cf. https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
        if (!scrollbarWidth) {
            // Creating invisible container
            var outer = document.createElement('div');
            outer.style.visibility = 'hidden';
            outer.style.overflow = 'scroll'; // forcing scrollbar to appear
            outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
            outer.style.padding = '0';
            outer.style.border = 'none';
            document.body.appendChild(outer);
            // Creating inner element and placing it in the container
            var inner = document.createElement('div');
            inner.style.padding = '0';
            inner.style.border = 'none';
            outer.appendChild(inner);
            // Calculating difference between container's full width and the child width
            scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
            // Removing temporary elements from the DOM
            outer.parentNode.removeChild(outer);
        }
        return scrollbarWidth;
    }
    function isVisibleOn($el, axis) {
        // cf. https://stackoverflow.com/questions/4814398/how-can-i-check-if-a-scrollbar-is-visible/4814526#4814526
        var el = $el[0];
        return axis === 'y'
            ? el.scrollHeight > el.clientHeight
            : el.scrollWidth > el.clientWidth;
    }
    function isMouseEventTarget(event) {
        var $target = $(event.target),
            borderRightWidth,
            borderBottomWidth;
        if (event.offsetX && isVisibleOn($target, 'y')) {
            borderRightWidth =
                parseInt(
                    $target.css('border-right-width').replace(/px$/, ''),
                    10,
                ) || 0;
            if (
                event.offsetX >=
                event.target.offsetWidth - getWidth() - borderRightWidth - 1
            ) {
                // NB: we substract 1 extra pixel as a safety net (seems to be necessary on some browsers)
                return true;
            }
        }
        if (event.offsetY && isVisibleOn($target, 'x')) {
            borderBottomWidth =
                parseInt(
                    $target.css('border-bottom-width').replace(/px$/, ''),
                    10,
                ) || 0;
            if (
                event.offsetY >=
                event.target.offsetHeight - getWidth() - borderBottomWidth - 1
            ) {
                // NB: we substract 1 extra pixel as a safety net (seems to be necessary on some browsers)
                return true;
            }
        }
        return false;
    }
    $.scrollbar = {
        getWidth: getWidth,
        isVisibleOn: isVisibleOn,
        isMouseEventTarget: isMouseEventTarget,
    };
})(jQuery);
