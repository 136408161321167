(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#travel-spot', $container);

        if ($root.length === 0) return;

        // Photos
        (function () {
            var $photos = $('#travel-spot-photos', $root),
                $photosList = $('> .list', $photos);

            // Enable photos dragging on small screens
            (function () {
                var $photosDragContainment;

                function refreshPhotosDragContainment() {
                    var listWidth = $photosList.width(),
                        overflow = Math.max(
                            0,
                            listWidth - $photosList.parent().width(),
                        );
                    $photosDragContainment.css({
                        left: -1 * overflow + 'px',
                        width: listWidth + overflow + 'px',
                    });
                    // make sure $photosList has not moved outside containment while resizing windows
                    var photosListPosition = $photosList.position();
                    if (
                        photosListPosition.left > 0 ||
                        photosListPosition.left < -1 * overflow
                    ) {
                        $photosList.css({
                            left:
                                photosListPosition.left > 0
                                    ? '0'
                                    : -1 * overflow + 'px',
                        });
                    }
                }
                function toggleDraggablePhotos() {
                    if (
                        window.matchMedia(
                            '(min-width: ' +
                                app.config.breakpoints.large +
                                'px)',
                        ).matches
                    ) {
                        // large screens: disable drag mode
                        if ($photosDragContainment) {
                            $photosList.draggabilly('destroy');
                            $photosDragContainment.remove();
                            $photosDragContainment = null;
                        }
                    } else {
                        // small screens: enable drag mode or refresh containment
                        if ($photosDragContainment) {
                            refreshPhotosDragContainment();
                        } else {
                            if ($photosList.length !== 0) {
                                $photosDragContainment = $(
                                    '<div class="travel-spot-photos-drag-containment"></div>',
                                )
                                    .css({
                                        position: 'absolute',
                                        top:
                                            $photosList.position().top +
                                            parseInt(
                                                $photosList.css('marginTop'),
                                                10,
                                            ),
                                        height: $photosList.height() + 'px',
                                    })
                                    .insertBefore($photosList);
                                refreshPhotosDragContainment();
                                $photosList.draggabilly({
                                    axis: 'x',
                                    containment: $photosDragContainment[0],
                                });
                            }
                        }
                    }
                }

                $(window).on('resize', $.debounce(100, toggleDraggablePhotos));
                toggleDraggablePhotos();
                $photosList.captureImgLoad(toggleDraggablePhotos);
            })();

            // Intercept dragging click events (shouldn't open dialog)
            // NB: dragEnd is triggered before click
            var photosListClickEvent;
            $photosList.on('click dragEnd', function (event) {
                if (event.type === 'click') {
                    if (photosListClickEvent === 'dragEnd') {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    photosListClickEvent = null;
                } else {
                    photosListClickEvent = event.type;
                }
            });
        })();

        // Climate
        (function () {
            var $climate = $('#travel-spot-climate', $root),
                $dataRows = $('.travel-spot-climate-data-row', $climate);

            // Responsive table
            $('.table', $climate)
                .on($.responsiveTable.TOGGLE, function (event, isResponsive) {
                    $dataRows
                        .toggleClass('is-responsive', isResponsive)
                        .toggleClass('is-not-responsive', !isResponsive);
                })
                .responsiveTable({
                    findHeader: function ($td, i) {
                        return $td
                            .closest('tbody')
                            .prev('thead')
                            .find('th:eq(' + (i + 1) + ')')
                            .attr('data-month');
                    },
                    headerSuffix: '',
                });

            // Enhance temperature/water cells
            var config = {
                temperature: {
                    range: {
                        // min/max temperatures
                        min: -10,
                        max: 30,
                    },
                    colors: [
                        // color range from min to max
                        '#3643BA',
                        '#4dbeea',
                        '#beefdd',
                        '#eed8c4',
                        '#ffc474',
                        '#faa656',
                    ],
                },
                water: {
                    range: {
                        // min/max temperatures
                        min: 0,
                        max: 30,
                    },
                    colors: [
                        // color range from min to max
                        '#3643BA',
                        '#4dbeea',
                        '#beefdd',
                        '#eed8c4',
                        '#ffc474',
                        '#faa656',
                    ],
                },
            };
            $dataRows.each(function (i, e) {
                $('.content.is-temperature', e).each(function (ii, ee) {
                    var $temperature = $(ee),
                        val = parseInt($temperature.attr('data-val'), 10) || 0;
                    var pct = Math.max(
                            0,
                            Math.min(
                                1,
                                (val - config.temperature.range.min) /
                                    (config.temperature.range.max -
                                        config.temperature.range.min),
                            ),
                        ),
                        idx = Math.round(
                            pct * (config.temperature.colors.length - 1),
                        );
                    $temperature.css(
                        'background-color',
                        config.temperature.colors[idx],
                    );
                });
                $('.content.is-water', e).each(function (ii, ee) {
                    var $water = $(ee),
                        val = parseInt($water.attr('data-val'), 10) || 0;
                    var pct = Math.max(
                            0,
                            Math.min(
                                1,
                                (val - config.temperature.range.min) /
                                    (config.temperature.range.max -
                                        config.temperature.range.min),
                            ),
                        ),
                        idx = Math.round(
                            pct * (config.temperature.colors.length - 1),
                        );
                    $('> .icon', $water).css(
                        'color',
                        config.temperature.colors[idx],
                    );
                });
            });
        })();
    });
})(jQuery, window.app);
