import Pikaday from 'pikaday';
import moment from 'moment';

(function ($) {
    var i18n = {
        fr: {
            previousMonth: 'Mois précédent',
            nextMonth: 'Mois suivant',
            months: [
                'Janvier',
                'Février',
                'Mars',
                'Avril',
                'Mai',
                'Juin',
                'Juillet',
                'Août',
                'Septembre',
                'Octobre',
                'Novembre',
                'Décembre',
            ],
            weekdays: [
                'dimanche',
                'lundi',
                'mardi',
                'mercredi',
                'jeudi',
                'vendredi',
                'samedi',
            ],
            weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        },
        en: {
            previousMonth: 'Previous Month',
            nextMonth: 'Next Month',
            months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            weekdays: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ],
            weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        },
    };
    $.fn.pikaday = function (o) {
        var $this = this,
            app = window.app;
        if (typeof Pikaday !== 'undefined')
            return this.each(function (i, e) {
                var $e = $(e),
                    instance = $e.data('pikaday');
                if (o === 'destroy' || o === 'detach') {
                    if (instance) {
                        if (o === 'destroy') instance.destroy();
                        $e.removeData('pikaday');
                    }
                } else if (o === 'sync') {
                    if (instance) {
                        instance.setMoment(
                            moment($e.val(), app.config.pikaday.format),
                        );
                    }
                } else if (!instance)
                    $e.data(
                        'pikaday',
                        new Pikaday(
                            $.extend(
                                {
                                    field: e,
                                    i18n: i18n[app.config.lang],
                                    firstDay: app.config.pikaday.firstDay,
                                    format: app.config.pikaday.format,
                                    minDate: new Date(),
                                },
                                o,
                            ),
                        ),
                    ).trigger('pikaday:ready');
            });
        return this;
    };
})(jQuery);
