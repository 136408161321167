(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#travel-theme', $container);

        if ($root.length === 0) return;

        // Navigation links
        (function () {
            var $links = $('#travel-theme-links', $root),
                $linksInner = $('> .inner', $links);

            // Enable links dragging on small screens
            var $linksDragContainment;
            function refreshLinksDragContainment() {
                var linksWidth = $linksInner.width(),
                    overflow = Math.max(
                        0,
                        linksWidth - $linksInner.parent().width(),
                    );
                $linksDragContainment.css({
                    left: -1 * overflow + 'px',
                    width: linksWidth + overflow + 'px',
                });
                // make sure $linksInner has not moved outside containment while resizing windows
                var linksInnerPosition = $linksInner.position();
                if (
                    linksInnerPosition.left > 0 ||
                    linksInnerPosition.left < -1 * overflow
                ) {
                    $linksInner.css({
                        left:
                            linksInnerPosition.left > 0
                                ? '0'
                                : -1 * overflow + 'px',
                    });
                }
            }
            function toggleDraggableLinks() {
                if (
                    window.matchMedia(
                        '(min-width: ' + app.config.breakpoints.large + 'px)',
                    ).matches
                ) {
                    // large screens: disable drag mode
                    if ($linksDragContainment) {
                        $linksInner.draggabilly('destroy');
                        $linksDragContainment.remove();
                        $linksDragContainment = null;
                    }
                } else {
                    // small screens: enable drag mode or refresh containment
                    if ($linksDragContainment) {
                        refreshLinksDragContainment();
                    } else {
                        if ($linksInner.length !== 0) {
                            $linksDragContainment = $(
                                '<div class="travel-theme-links-drag-containment"></div>',
                            )
                                .css({
                                    position: 'absolute',
                                    top:
                                        $linksInner.position().top +
                                        parseInt(
                                            $linksInner.css('marginTop'),
                                            10,
                                        ),
                                    height: $linksInner.height() + 'px',
                                })
                                .insertBefore($linksInner);

                            refreshLinksDragContainment();
                            $linksInner.draggabilly({
                                axis: 'x',
                                containment: $linksDragContainment[0],
                            });
                        }
                    }
                }
            }
            $(window).on('resize', $.debounce(100, toggleDraggableLinks));
            toggleDraggableLinks();
        })();
    });
})(jQuery, window.app);
