(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('body', $container);

        if ($root.length === 0) return;

        // Toggle .has-modal class on dialog opening
        $root
            .on($.dialog.OPEN, function () {
                $root.addClass('has-modal');
            })
            .on($.dialog.CLOSE, function () {
                $root.removeClass('has-modal');
            });

        // Compute scrollbar width
        document.documentElement.style.setProperty(
            '--scrollbar-width',
            $.scrollbar.getWidth() + 'px',
        );
    });
})(jQuery, window.app);
