(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#booking-block-dialog', $container);

        if ($root.length === 0) return;

        var min = 1;
        var max = 10;
        var $quantity_div = $('#modal-quantity', $root);

        $('#modal-quantity-selector-button-minus', $quantity_div).on(
            'click',
            function () {
                var quantity = parseInt(
                    $('#modal-quantity-selector-input').val(),
                );
                min = this.dataset.minpax;
                var newQuantity = quantity - 1;
                if (newQuantity < min) {
                    newQuantity = min;
                }
                if (newQuantity == min) {
                    removeAttrDisabled(
                        $(
                            '#modal-quantity-selector-button-plus',
                            $quantity_div,
                        ),
                    );
                }
                if (newQuantity == min) {
                    addAttrDisabled(
                        $(
                            '#modal-quantity-selector-button-minus',
                            $quantity_div,
                        ),
                    );
                }
                $('#modal-quantity-selector-input', $quantity_div).val(
                    newQuantity,
                );
            },
        );

        $('#modal-quantity-selector-button-plus', $quantity_div).on(
            'click',
            function () {
                var quantity = parseInt(
                    $('#modal-quantity-selector-input').val(),
                );
                var newQuantity = quantity + 1;
                if (newQuantity > max) {
                    newQuantity = max;
                }
                if (newQuantity < max) {
                    removeAttrDisabled(
                        $(
                            '#modal-quantity-selector-button-minus',
                            $quantity_div,
                        ),
                    );
                }
                if (newQuantity == max) {
                    addAttrDisabled(
                        $(
                            '#modal-quantity-selector-button-plus',
                            $quantity_div,
                        ),
                    );
                }
                $('#modal-quantity-selector-input', $quantity_div).val(
                    newQuantity,
                );
            },
        );

        $('#modal-quantity-selector-input', $quantity_div).on(
            'change',
            function () {
                if (
                    isNaN(parseInt($('#modal-quantity-selector-input').val()))
                ) {
                    $('#modal-quantity-selector-input', $quantity_div).val(min);
                    addAttrDisabled(
                        $(
                            '#modal-quantity-selector-button-minus',
                            $quantity_div,
                        ),
                    );
                    removeAttrDisabled(
                        $(
                            '#modal-quantity-selector-button-plus',
                            $quantity_div,
                        ),
                    );
                }
                if (parseInt($('#modal-quantity-selector-input').val()) < min) {
                    $('#modal-quantity-selector-input', $quantity_div).val(min);
                    addAttrDisabled(
                        $(
                            '#modal-quantity-selector-button-minus',
                            $quantity_div,
                        ),
                    );
                    removeAttrDisabled(
                        $(
                            '#modal-quantity-selector-button-plus',
                            $quantity_div,
                        ),
                    );
                }
                if (parseInt($('#modal-quantity-selector-input').val()) > max) {
                    $('#modal-quantity-selector-input', $quantity_div).val(max);
                    addAttrDisabled(
                        $(
                            '#modal-quantity-selector-button-plus',
                            $quantity_div,
                        ),
                    );
                    removeAttrDisabled(
                        $(
                            '#modal-quantity-selector-button-minus',
                            $quantity_div,
                        ),
                    );
                }
            },
        );

        function addAttrDisabled(elem) {
            elem.attr('disabled', 'disabled');
        }

        function removeAttrDisabled(elem) {
            elem.removeAttr('disabled');
        }
    });
})(jQuery, window.app);
