(function ($, app) {
    var $mediaDialog;

    // Refresh media dialog contents (= load photo or embed url)
    function refreshMediaDialog(url, isEmbed) {
        var $photo = $('.photo', $mediaDialog),
            $embed = $('.embed', $mediaDialog);
        $mediaDialog
            .toggleClass('is-photo', !isEmbed)
            .toggleClass('is-embed', isEmbed);
        if (isEmbed) {
            $photo.attr('src', '');
            if ($embed.attr('src') !== url) {
                // don't load twice the same url
                $embed.attr('src', url);
            }
            $mediaDialog.removeClass('is-error').removeClass('is-loading');
        } else {
            $embed.attr('src', '');
            if ($photo.attr('src') !== url) {
                // don't load twice the same url
                $photo.attr('src', '');
                $mediaDialog.removeClass('is-error').addClass('is-loading');
                $photo.attr('src', url);
            } else {
                $mediaDialog.removeClass('is-error').removeClass('is-loading');
            }
        }
        /*$('> nav > .prev',$mediaDialog).toggleClass('is-disabled',$mediaDialogItem.prev().length===0);
        $('> nav > .next',$mediaDialog).toggleClass('is-disabled',$mediaDialogItem.next().length===0);*/
    }

    app.on('dom:ready', function ($container) {
        var $links = $('.front-media-dialog-link', $container);

        if ($links.length === 0) return;

        // Initialize media dialog
        if (!$mediaDialog) {
            $mediaDialog = $(
                '<section id="front-media-dialog" class="front-media-dialog dialog is-modal">' +
                    '<figure><img class="photo" src="" alt=""><iframe class="embed" src="" allowfullscreen></iframe></figure>' +
                    //'<nav class="nav"><a class="prev" href="javascript:void(0)"></a><a class="next" href="javascript:void(0)"></a></nav>'+
                    '</section>',
            ).appendTo('body');

            // When media dialog opens, refresh its contents
            $mediaDialog.on($.dialog.OPEN, function (event, initiator) {
                var $initiator = $(initiator);
                if ($initiator.is('[data-photo]')) {
                    refreshMediaDialog($initiator.attr('data-photo'), false);
                } else if ($initiator.is('[data-embed]')) {
                    refreshMediaDialog($initiator.attr('data-embed'), true);
                }
            });

            // When media dialog closes, clear embed src attribute
            $mediaDialog.on($.dialog.CLOSE, function () {
                $('.embed', $mediaDialog).attr('src', '');
            });

            // Handle media dialog img special classes
            $('img', $mediaDialog).on('load error', function (event) {
                $mediaDialog.removeClass('is-loading');
                if (event.type === 'error') {
                    $mediaDialog.addClass('is-error');
                }
            });
        }

        // Open dialog on click
        $links.on('click', function (event) {
            event.preventDefault();
            $mediaDialog.dialog('show', this);
        });
    });
})(jQuery, window.app);
