(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#flat-landingpage', $container);

        if ($root.length === 0) return;

        // Top destinations
        (function () {
            var $topDestinations = $(
                '.flat-landingpage-destinations-top',
                $root,
            );

            // Megalink on top destinations
            $('.destination', $topDestinations).megalink({
                selector: '.heading > .link',
            });
        })();

        // Other destinations
        (function () {
            var $otherDestinations = $(
                '.flat-landingpage-destinations-others',
                $root,
            );

            if ($otherDestinations.length === 0) return;

            // Slideshow
            var $list = $('> .inner > .list', $otherDestinations);

            $list
                .flickity({
                    adaptiveHeight: true,
                    cellAlign: 'left',
                    groupCells: true,
                    prevNextButtons: $list.children().length > 1,
                    pageDots: false,
                })
                .captureImgLoad(function () {
                    $list.flickity('resize');
                });
        })();

        // Top themes
        (function () {
            var $topThemes = $('.flat-landingpage-themes-top', $root);

            // Megalink on top themes
            $('.theme', $topThemes).megalink({
                selector: '.heading > .link',
            });
        })();

        // Other themes
        (function () {
            var $otherThemes = $('.flat-landingpage-themes-others', $root);

            if ($otherThemes.length === 0) return;

            // Slideshow
            var $list = $('> .inner > .list', $otherThemes);

            $list
                .flickity({
                    adaptiveHeight: true,
                    cellAlign: 'left',
                    groupCells: true,
                    prevNextButtons: $list.children().length > 1,
                    pageDots: false,
                })
                .captureImgLoad(function () {
                    $list.flickity('resize');
                });
        })();
    });
})(jQuery, window.app);
