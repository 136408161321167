(function ($) {
    var CLASSES = {
        root: 'dialog-root',
        open: 'is-open',
        modal: 'is-modal',
        backdrop: 'dialog-backdrop',
        close: 'dialog-close',
    };
    var EVENTS = {
        init: 'dialog:init',
        open: 'dialog:open',
        close: 'dialog:close',
    };
    var $ROOT;

    function Dialog($el, o) {
        var self = this;
        self.$el = $el;
        self.options = $.extend({}, o);
        if (!$ROOT) {
            $ROOT = $('<div class="' + CLASSES.root + '"></div>').appendTo(
                'body',
            );
        }
        $ROOT.append(self.$el);
        if ($('.' + CLASSES.close, self.$el).length === 0) {
            self.$el.append(
                '<a href="#" rel="dialog:close" class="' +
                    CLASSES.close +
                    '"></a>',
            );
        }
        self.$el.trigger(EVENTS.init);
    }

    Dialog.prototype.isOpen = function () {
        var self = this;
        return self.$el.hasClass(CLASSES.open);
    };

    Dialog.prototype.isModal = function () {
        var self = this;
        return self.$el.hasClass(CLASSES.modal);
    };

    Dialog.prototype.show = function (initiator) {
        var self = this;
        if (!self.isOpen()) {
            if (
                self.isModal() &&
                self.$el.parent('.' + CLASSES.backdrop).length === 0
            ) {
                self.$el.wrap('<div class="' + CLASSES.backdrop + '"></div>');
            }
            self.$el.addClass(CLASSES.open);
            self.$el.trigger(EVENTS.open, [initiator]);
        }
    };

    Dialog.prototype.close = function (initiator) {
        var self = this;
        if (self.isOpen()) {
            self.$el.removeClass(CLASSES.open);
            self.$el.unwrap('.' + CLASSES.backdrop);
            self.$el.trigger(EVENTS.close, [initiator]);
        }
    };

    // Event constants
    $.dialog = {
        INIT: EVENTS.init,
        OPEN: EVENTS.open,
        CLOSE: EVENTS.close,
    };

    $.fn.dialog = function (o, initiator) {
        return this.each(function (i, e) {
            var $e = $(e),
                instance = $e.data('dialog');
            if (
                (typeof o === 'string' || o instanceof String) &&
                o.match(/^show|close$/)
            ) {
                if (instance) instance[o](initiator);
                else {
                    $e.dialog().dialog(o, initiator); // auto-instantiate and execute
                }
            } else if (!instance) $e.data('dialog', new Dialog($e, o));
        });
    };

    var _mouseDownTarget;
    $(document)
        .on('click.dialog', '[rel~="dialog:show"]', function (event) {
            event.preventDefault();
            var $this = $(this),
                href = $this.attr('data-href') || $this.attr('href'),
                $dialog = $(href && href.match(/^#/) ? href : '');
            if ($dialog.length > 0) {
                $dialog.dialog('show', this);
            }
        })
        .on('click.dialog', '[rel~="dialog:close"]', function (event) {
            event.preventDefault();
            if (!$ROOT) return;
            var $dialog = $(this).closest('.dialog');
            if ($dialog.length > 0 && $dialog.data('dialog')) {
                $dialog.dialog('close', this);
            }
        })
        .on('mousedown.dialog', '.' + CLASSES.backdrop, function (event) {
            if ($.scrollbar.isMouseEventTarget(event)) {
                _mouseDownTarget = null;
            } else {
                _mouseDownTarget = event.target;
            }
        })
        .on('mouseup.dialog', '.' + CLASSES.backdrop, function (event) {
            if (
                event.target === this &&
                event.target === _mouseDownTarget &&
                !$.scrollbar.isMouseEventTarget(event)
            ) {
                // enforce static click on backdrop (not content, not scrollbar)
                event.preventDefault();
                var $dialog = $(this).children('.dialog');
                if ($dialog.length > 0 && $dialog.data('dialog')) {
                    $dialog.dialog('close', this);
                }
            }
            _mouseDownTarget = null;
        });
})(jQuery);
